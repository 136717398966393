<template>
  <div>
    <div v-if="!meetsDisplayCondition()">
      <div
        v-if="debug"
        class="col-span-6 text-sm font-mono border-green-500 border-2 bg-gray-200 mb-4"
      >
        <span>{{condition}}</span>
      </div>
    </div>
    <div
      class="grid grid-cols-6 gap-6"
      v-if="meetsDisplayCondition()"
      :class="{'border-2 border-blue-500 mb-4':debug}"
    >
      <div v-if="debug" class="col-span-6 text-sm font-mono">
        {{field}}:
        <span class="text-green-800 border-2 border-green-500">{{inputValue}}</span>
      </div>

      <div class="col-span-6 mb-4" :class="errors ? 'border-red-500 border-l-4 pl-4' : ''">
        <div v-if="field.type != 'date' && field.type != 'time'" class="col-span-6">
          <text-field
            v-if="field.type == 'text'"
            v-model="inputValue"
            @input="$emit('input', inputValue)"
            v-bind:required="required"
            v-bind:autocomplete="autocomplete"
            v-bind:help="field.notes"
          >{{ field.name }}</text-field>

          <select-field
            v-if="field.type == 'select'"
            v-model="inputValue"
            v-bind:name="'field_'+id"
            v-bind:options="field.options"
            v-bind:help="field.notes"
            @input="$emit('input', inputValue)"
          >{{ field.name }}</select-field>

          <select-field
            v-if="field.type == 'multiselect'"
            v-model="inputValue"
            v-bind:name="'field_'+field.id"
            v-bind:options="field.options"
            v-bind:first="first"
            v-bind:help="field.notes"
            @input="$emit('input', inputValue)"
          >{{ field.name }}</select-field>

          <number-field
            v-if="field.type == 'number' || field.type == 'money'"
            v-model="inputValue"
            @input="$emit('input', inputValue)"
            v-bind:required="required"
            v-bind:autocomplete="autocomplete"
            v-bind:help="field.notes"
          >{{ field.name }}</number-field>

          <select-field
            v-if="field.type == 'yes_no'"
            v-model="inputValue"
            v-bind:name="'field_'+id"
            v-bind:options="[{'id':'t', 'name':'Yes'}, {'id':'f', 'name':'No'}]"
            v-bind:help="field.notes"
            @input="$emit('input', inputValue)"
          >{{ field.name }}</select-field>

          <text-area-field
            v-if="field.type == 'textarea'"
            v-model="inputValue"
            v-bind:name="'field_'+field.id"
            v-bind:help="field.notes"
            @input="$emit('input', inputValue)"
          >{{ field.name }}</text-area-field>

          <subsection-field
            v-if="field.type == 'subsection_label'"
            v-model="inputValue"
            v-bind:name="'field_'+field.id"
            v-bind:help="field.notes"
            @input="$emit('input', inputValue)"
          >{{ field.name }}</subsection-field>
        </div>

        <div v-if="field.type =='date' || field.type == 'time'" class="col-span-3">
          <date-field
            v-if="field.type=='date'"
            v-model="inputValue"
            v-bind:name="'field_'+field.id"
            @input="$emit('input', inputValue)"
            v-bind:timezone="'UTC'"
            v-bind:help="field.notes"
          >{{ field.name }}</date-field>

          <time-field
            v-if="field.type=='time'"
            v-model="inputValue"
            v-bind:name="'field_'+field.id"
            @input="$emit('input', inputValue)"
            v-bind:timezone="'UTC'"
            v-bind:help="field.notes"
          >{{ field.name }}</time-field>
        </div>

        <div class="col-span-6 text-red-500 mt-4" v-if="errors">{{ errors.message }}</div>
        <div v-if="debug" class="col-span-6 text-sm font-mono">
          <span class="text-green-800 border-2 border-green-500">{{condition}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "./TextField.vue";
import SelectField from "./SelectField";
import NumberField from "./NumberField";
import DateField from "./DateField";
import TimeField from "./TimeField";
import TextAreaField from "./TextAreaField";
import SubsectionField from "./SubsectionField";

export default {
  components: {
    TextField,
    SelectField,
    NumberField,
    DateField,
    TimeField,
    TextAreaField,
    SubsectionField
  },
  name: "CustomField",
  props: [
    "field",
    "required",
    "autocomplete",
    "id",
    "timezone",
    "first",
    "errors",
    "conditions",
    "responses",
    "debug"
  ],
  data() {
    return {
      inputValue: null,
      condition: false
    };
  },
  methods: {
    meetsDisplayCondition: function() {
      if (!this.conditions) return true;

      const condition = this.conditions.find(
        el => parseInt(el.id) == parseInt(this.field.events_custom_id)
      );
      this.condition = condition;

      if (!condition) return true;

      let match = true;
      for (let rule of condition.depends) {
        const comparisonValue = this.responses["cust_" + rule.id.toString()];
        match = match && comparisonValue === rule.value;
        if (match) {
          console.log("Match on field: ", this.field);
          console.log("Match comparison: ", rule);
        }
      }

      if (!match) {
        this.$emit("input", null); // invisible fields shouldn't submit values
      }
      return match;
    }
  }
};
</script>

<style scoped>
</style>