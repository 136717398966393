<template>
  <div>
    <label><slot></slot><span v-if="required" class="text-xs text-red-500"> (required)</span></label>
    <help-text :value="help"></help-text>
    <v-date-picker mode="date" v-model="inputValue" :minute-increment="15" :disabled-dates="{end:new Date()}"
                   :timezone="timezone"
                   @input="emit" :required="required" >
      <template v-slot="{ inputValue, inputEvents }">
        <input
            class="bg-white border border-gray-300 px-2 py-1 rounded shadow-sm"
            :value="inputValue"
            v-on="inputEvents"
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import HelpText from "./HelpText.vue";
export default {
  name: "DateField",
  components: {HelpText},
  created() {
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  props: ['value', 'required', 'autocomplete', 'timezone', 'help'],
  methods: {
    emit: function() {

      // We don't store timezones in our timestamps so this just gives us the time part as it was entered.

      let date = new Date(this.inputValue);
      let day = date.getUTCDate();
      let month = date.getUTCMonth()+1;
      let year = date.getUTCFullYear();
      month = month < 10 ? '0'+month : month;

      this.$emit('input', month + '/' + day + '/' + year);

    }
  }

}
</script>

<style scoped>

</style>