<template>
  <div>
    <gatekeeper
      v-if="!authenticated && requiresAuthentication"
      :configuration="configuration"
      v-model="response.email"
      @authenticated="onAuthenticated"
    ></gatekeeper>

    <div v-show="(authenticated || !requiresAuthentication)">
      <div class="max-w-7xl sm:px-6 px-4 py-12 mx-auto">
        <div class="text-center">
          <p class="inline-flex items-center mb-8">
            <img class="max-h-32" :src="configuration.s3_logo_link" alt="Logo" />
          </p>
          <p
            class="sm:text-5xl sm:tracking-tight lg:text-6xl mt-1 text-4xl font-extrabold"
          >{{ configuration.page_title }}</p>
          <div v-if="configuration.instructions" class="pt-8">
            <span v-html="configuration.instructions"></span>
          </div>
        </div>

        <div
          v-for="link in configuration.links"
          v-bind:key="link.id"
          class="text-center text-blue-500 pt-8"
        >
          <a
            v-if="!link.custom_domain"
            :href="'https://'+link.prefix+'.'+hostname"
          >Go To {{link.page_title}} Request Page</a>
          <a
            v-if="link.custom_domain"
            :href="'https://'+link.custom_domain+'/'+link.custom_domain_path"
          >Go To {{link.page_title}} Request Page</a>
        </div>
      </div>

      <div class="divide-y">
        <template v-if="showUserFilters()">
          <div class="max-w-7xl sm:px-6 sm:py-12 pb-12 mx-auto space-y-8 divide-y divide-gray-200">
            <div class="sm:p-6 px-4 py-5 divide-y divide-gray-200 space-y-8">
              <div class="md:grid md:grid-cols-3">
                <div class="md:col-span-1">
                  <h3
                    class="text-xl font-medium leading-6"
                  >{{configuration.layout_settings.user_filters_title || 'Filters'}}</h3>
                  <p
                    class="opacity-70 mt-1 text-sm"
                  >{{configuration.layout_settings.user_filters_help}}</p>
                </div>
                <div class="md:mt-0 mt-5 md:col-span-2">
                  <div class="grid grid-cols-6 gap-6">
                    <template v-for="filter in configuration.user_filter_settings.fields">
                      <template v-if="filter == 'city'">
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <input type="text" v-model="filters.city" />
                        </div>
                      </template>

                      <template v-if="filter == 'state'">
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <states
                            v-model="filters.state"
                            v-bind:config="configuration.prefilter_settings.state"
                          ></states>
                        </div>
                      </template>

                      <template v-if="filter == 'zip'">
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <input type="text" v-model="filters.zip" />
                        </div>
                      </template>

                      <template
                        v-if="filter == 'event_status' && configuration.event_statuses.length > 1"
                      >
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.event_status">
                            <option></option>
                            <option
                              v-for="status in configuration.event_statuses"
                              :value="status.id"
                              v-bind:key="status.id"
                            >{{ status.name }}</option>
                          </select>
                        </div>
                      </template>

                      <template v-if="filter == 'market' && configuration.markets.length > 1">
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.market">
                            <option></option>
                            <option
                              v-for="market in configuration.markets"
                              :value="market.id"
                              v-bind:key="market.id"
                            >{{ market.name }}</option>
                          </select>
                        </div>
                      </template>

                      <template v-if="filter == 'program' && configuration.programs.length > 1">
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.program">
                            <option></option>
                            <option
                              v-for="program in configuration.programs"
                              :value="program.id"
                              v-bind:key="program.id"
                            >{{ program.name }}</option>
                          </select>
                        </div>
                      </template>

                      <template
                        v-if="filter == 'venue_type' && configuration.venue_types.length > 1"
                      >
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.venue_type">
                            <option></option>
                            <option
                              v-for="type in configuration.venue_types"
                              :value="type.id"
                              v-bind:key="type.id"
                            >{{ type.name }}</option>
                          </select>
                        </div>
                      </template>

                      <template
                        v-if="filter == 'event_type' && configuration.event_types.length > 1"
                      >
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.event_type">
                            <option></option>
                            <option
                              v-for="type in configuration.event_types"
                              :value="type.id"
                              v-bind:key="type.id"
                            >{{ type.name }}</option>
                          </select>
                        </div>
                      </template>

                      <template
                        v-if="filter == 'event_category' && configuration.event_categories.length > 1"
                      >
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.event_category">
                            <option></option>
                            <option
                              v-for="category in configuration.event_categories"
                              :value="category.id"
                              v-bind:key="category.id"
                            >{{ category.name }}</option>
                          </select>
                        </div>
                      </template>

                      <template v-if="filter == 'asset' && configuration.assets.length > 1">
                        <div class="sm:col-span-3 col-span-6 pb-4">
                          <label>{{ configuration.user_filter_settings['heading_'+filter] || capitalize(filter) }}</label>
                          <p
                            class="opacity-70 mt-1 text-sm"
                          >{{ configuration.user_filter_settings['help_'+filter] || '' }}</p>
                          <select v-model="filters.asset">
                            <option></option>
                            <option
                              v-for="asset in configuration.assets"
                              :value="asset.id"
                              v-bind:key="asset.id"
                            >{{ asset.name }}</option>
                          </select>
                        </div>
                      </template>
                    </template>
                  </div>

                  <button
                    @click="loadEvents()"
                    class="col-span-2 filter hover:brightness-110 hover:saturate-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-accent bg-skin-accent sm:w-auto flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm"
                  >Filter Events</button>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="configuration.show_map">
          <div class="max-w-7xl sm:px-6 sm:py-12 pb-12 mx-auto space-y-8 divide-y">
            <div class="sm:p-6 px-4 py-5 space-y-8">
              <div class="md:grid md:grid-cols-3">
                <div class="md:col-span-1">
                  <h3
                    class="text-xl font-medium leading-6"
                  >{{configuration.layout_settings.map_title || 'Event Map'}}</h3>
                  <p class="opacity-70 mt-1 text-sm">{{configuration.layout_settings.map_help }}</p>
                </div>
                <div class="md:mt-0 mt-5 md:col-span-2">
                  <div class="py-5 space-y-8">
                    <div class="grid">
                      <div id="map"></div>
                      <ul class="inline-flex">
                        <li class="pr-4" v-for="col in calendarColors">
                          <div
                            class="w-5 h-5 rounded-full inline-flex align-middle"
                            :style="{'background-color':col.color}"
                          ></div>
                          {{col.status}}
                        </li>
                      </ul>

                      <event-list-item
                        class="py-4"
                        :events="displayedEventsUnderMap"
                        :configuration="configuration"
                        :fields="enabledFields"
                      ></event-list-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="configuration.show_calendar">
          <div class="max-w-7xl sm:px-6 sm:py-12 pb-12 mx-auto space-y-8 divide-y">
            <div class="sm:p-6 px-4 py-5 space-y-8">
              <div class="md:grid md:grid-cols-3">
                <div class="md:col-span-1">
                  <h3
                    class="text-xl font-medium leading-6"
                  >{{configuration.layout_settings.calendar_title || 'Event Calendar'}}</h3>
                  <p
                    class="opacity-70 mt-1 text-sm"
                  >{{configuration.layout_settings.calendar_help }}</p>
                </div>
                <div class="md:mt-0 mt-5 md:col-span-2">
                  <div class="py-5 space-y-8">
                    <div class="grid">
                      <FullCalendar
                        v-if="(authenticated || !requiresAuthentication)"
                        :options="calendarOptions"
                      />
                      <ul class="inline-flex">
                        <li class="pr-4" v-for="col in calendarColors">
                          <div
                            class="w-5 h-5 rounded-full inline-flex align-middle"
                            :style="{'background-color':col.color}"
                          ></div>
                          {{col.status}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="configuration.show_event_list && this.events.length > 0">
          <div class="max-w-7xl sm:px-6 sm:py-12 pb-12 mx-auto space-y-8 divide-y divide-gray-200">
            <div class="sm:p-6 px-4 py-5 divide-y divide-gray-200 space-y-8">
              <div class="md:grid md:grid-cols-3">
                <div class="md:col-span-1">
                  <h3
                    class="text-xl font-medium leading-6"
                  >{{configuration.layout_settings.event_list_title|| 'Event List'}}</h3>
                  <p
                    class="opacity-70 mt-1 text-sm"
                  >{{configuration.layout_settings.event_list_help}}</p>
                </div>
                <div class="md:mt-0 mt-5 md:col-span-2">
                  <event-list-item
                    :events="events.filter(el=>el.on_event_list || el.on_list)"
                    :configuration="configuration"
                    :fields="enabledFields"
                  ></event-list-item>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      ref="popoverRef"
      v-bind:class="{'hidden': !popoverShow, 'block': popoverShow}"
      class="bg-white border mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg shadow-md"
    >
      <div>
        <div
          @click="hidePopover()"
          class="bg-blue-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
        >{{ selectedCalendarEvent.event_name || 'Event' }}</div>
        <div class="text-black p-3">
          <table>
            <tr v-for="field in enabledFields" v-bind:key="field.key">
              <td class="pr-4">{{field.name}}</td>
              <td>{{selectedCalendarEvent[field.key]}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Gatekeeper from "./components/Gatekeeper.vue";
import EventListItem from "./components/EventListItem.vue";
import States from "./components/StatesSelect.vue";
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import { createPopper } from "@popperjs/core";
import { compareAsc, format } from "date-fns";

export default {
  components: { Gatekeeper, States, FullCalendar, EventListItem },

  props: ["config", "states"],

  computed: {
    requiresAuthentication: function() {
      return (
        this.configuration.password_authentication ||
        this.configuration.email_authentication
      );
    },
    enabledFields: function() {
      if (this.configuration.event_list_fields) {
        let j = this.configuration.event_list_fields.filter(el => el.enabled);
        return j;
      }
      return [];
    }
  },

  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        events: [],
        eventClick: this.onCalendarClick,
        contentHeight: "auto"
      },
      popoverShow: false,
      submitting: false,
      showCalendar: false,
      response: {},
      keyInputString: "",
      validationErrors: [],
      configuration: this.config,
      authenticated: false,
      filters: {},
      events: [],
      markers: [],
      maxZIndex: 1,
      mapLinePaths: [],
      displayedEventsUnderMap: [],
      calendarColors: [],
      selectedCalendarEvent: {},
      hostname: window.location.hostname
        .split(".")
        .slice(-2)
        .join(".")
    };
  },

  methods: {
    hidePopover: function() {
      this.popoverShow = false;
    },

    onCalendarClick: function(arg) {
      var ev = this.events.find(
        el => el.event_id == arg.event.extendedProps.eventId
      );
      this.selectedCalendarEvent = ev;

      if (this.popoverShow) {
        this.popoverShow = false;
      }

      this.popoverShow = true;
      createPopper(arg.el, this.$refs.popoverRef, {
        placement: "bottom"
      });
    },

    showUserFilters: function() {
      return (
        this.configuration.show_user_filters &&
        this.configuration.user_filter_settings &&
        this.configuration.user_filter_settings.fields.length > 0
      );
    },

    capitalize: function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).replace("_", " ");
    },

    log(...msg) {
      if (this.debug) {
        console.log(msg);
      }
    },

    loadEvents() {
      axios
        .post("/api/v1/events", {
          filters: this.filters,
          id: this.configuration.id
        })
        .then(response => {
          if (response.data.data) {
            this.events = response.data.data;
            this.events = this.events.sort(
              (a, b) => new Date(a.which_date) - new Date(b.which_date)
            );
          } else {
            this.events = [];
          }

          this.buildCalendar();
          this.buildMap();
        })
        .catch(err => {
          Vue.swal({
            icon: "error",
            title: "Error",
            text: "Error communicating with server"
          });
          console.log(err);
        });
    },

    buildMap() {
      if (!this.configuration.show_map) return;
      let vm = this;

      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 39.8283, lng: -98.5795 },
        zoom: 4,
        disableDefaultUI: true,
        zoomControl: true
      });

      var pinColor = "#FFFFFF";

      // Pick your pin (hole or no hole)
      var pinSVGHole =
        "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
      var labelOriginHole = new google.maps.Point(12, 15);
      var pinSVGFilled =
        "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
      var labelOriginFilled = new google.maps.Point(12, 9);

      var markerImage = {
        // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGFilled,
        anchor: new google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 2,
        strokeColor: "white",
        scale: 2,
        labelOrigin: labelOriginFilled
      };
      var label = {
        text: "-",
        color: "white",
        fontSize: "12px"
      }; // https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol
      this.marker = new google.maps.Marker({
        map: map.MapObject,
        position: this.geographicCoordinates,
        icon: markerImage
        //OPTIONAL: animation: google.maps.Animation.DROP,
      });

      let colorIdentifier = "event_status";
      if (this.configuration.layout_settings) {
        colorIdentifier =
          this.configuration.layout_settings.calendar_color_by ||
          "event_status";
      }

      var selectedMarkerImage = {
        path: pinSVGFilled,
        anchor: new google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: "#FF2200",
        strokeWeight: 2,
        strokeColor: "black",
        scale: 2.5,
        labelOrigin: labelOriginFilled
      };
      var previousOrNextImage = {
        path: pinSVGFilled,
        anchor: new google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: "#FFA500",
        strokeWeight: 2,
        strokeColor: "black",
        scale: 2.5,
        labelOrigin: labelOriginFilled
      };

      for (let event of this.events) {
        var markerImage = {
          // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
          path: pinSVGFilled,
          anchor: new google.maps.Point(12, 17),
          fillOpacity: 1,
          fillColor: event[colorIdentifier + "_color"],
          strokeWeight: 2,
          strokeColor: "white",
          scale: 2,
          labelOrigin: labelOriginFilled
        };
        let marker = new google.maps.Marker({
          position: { lat: parseFloat(event.lat), lng: parseFloat(event.lon) },
          map: map,
          title: event.name,
          defaultIcon: markerImage,
          previousOrNextIcon: previousOrNextImage,
          selectedIcon: selectedMarkerImage,
          icon: markerImage,
          event: event
        });

        marker.addListener("click", function() {
          let eventId = marker.event.event_id;
          let previous = false;
          let next = false;

          for (var m in vm.markers) {
            vm.markers[m].setIcon(vm.markers[m].defaultIcon);
            vm.markers[m].setLabel(null);
          }

          let assetMarkers = vm.markers.filter(
            el => el.event.asset == marker.event.asset
          );

          let index;
          for (index = 0; index < assetMarkers.length; index++) {
            if (assetMarkers[index].event.event_id == marker.event.event_id)
              break;
          }

          // find preceding and next
          /*

          if (index > 0) {
            let previous = assetMarkers[index-1];
            previous.setIcon(previous.previousOrNextIcon);
            previous.setZIndex(1000 + vm.maxZIndex++);
            label.text = format(new Date(previous.event.start_timestamp.replace(' ', 'T')), 'MM/dd');
            previous.setLabel(label);

            // vm.mapLinePaths.push(new google.maps.Polyline({
            //   path: [{lat:parseInt(assetMarkers[index].event.lat), lng:parseInt(assetMarkers[index].event.lon)},{lat:parseInt(assetMarkers[index-1].event.lat), lng:parseInt(assetMarkers[index-1].event.lon)}], 
            //   strokeColor: '#FF0000',
            //   icons: [
            //     {
            //       icon: {
            //         path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
            //       },
            //       offset: "100%"
            //     }
            //   ],
            //   map: map
            // }
            // ));
          }

          if (index < assetMarkers.length) {
            let next = assetMarkers[index+1];
            next.setIcon(next.previousOrNextIcon);
            next.setZIndex(1000 + vm.maxZIndex++);
            label.text = format(new Date(next.event.start_timestamp.replace(' ', 'T')), 'MM/dd');
            next.setLabel(label);
            // console.log('next', (index+1), next.event.event_id, next.event.start_timestamp);
          }


          label.text = format(new Date(marker.event.start_timestamp.replace(' ', 'T')), 'MM/dd');
          marker.setLabel(label);

          */

          marker.setIcon(marker.selectedIcon);
          marker.setZIndex(1000 + vm.maxZIndex++);

          // Find any events events with linked to this one

          let displayedEvents = [];
          for (let currentIndex = 0; currentIndex <= index; currentIndex++) {
            if (
              assetMarkers[currentIndex].event.lat == marker.event.lat &&
              assetMarkers[currentIndex].event.lon == marker.event.lon
            ) {
              displayedEvents.push(assetMarkers[currentIndex].event);
            }
          }

          for (
            let currentIndex = index + 1;
            currentIndex < assetMarkers.length;
            currentIndex++
          ) {
            if (
              assetMarkers[currentIndex].event.lat == marker.event.lat &&
              assetMarkers[currentIndex].event.lon == marker.event.lon
            ) {
              displayedEvents.push(assetMarkers[currentIndex].event);
            }
          }
          vm.displayedEventsUnderMap = displayedEvents;
        });

        this.markers.push(marker);
      }
    },

    buildCalendar() {
      let attributes = [];

      attributes.push({
        highlight: "blue",
        dates: new Date()
      });

      this.calendarOptions.events = [];
      this.calendarColors = [];

      let colorIdentifier = "event_status";
      if (this.configuration.layout_settings) {
        colorIdentifier =
          this.configuration.layout_settings.calendar_color_by ||
          "event_status";
      }

      // Unfortunately on the server side 'event_status' in the event object got renamed to just 'status' which
      // means if 'event_status' is the selected color_by we need to actually make it use event.status instead.
      // This should get fixed server-side by pushing event_status as originally done but this hack fixes it for now. RD 3/22

      for (let e of this.events.filter(el => el.on_calendar)) {
        this.calendarOptions.events.push({
          title: e.event_name || e.city || "Event",
          date: new Date(e.start_date),
          allDay: true,
          color: e[colorIdentifier + "_color"],
          eventId: e.event_id
        });

        if (
          !this.calendarColors.find(
            el => el.status == e[colorIdentifier] || el.status == e.status
          )
        ) {
          this.calendarColors.push({
            status:
              colorIdentifier == "event_status" ? e.status : e[colorIdentifier],
            color: e[colorIdentifier + "_color"]
          });
        }
      }
      this.calendarColors.sort((a, b) => a.status > b.status);
    },

    onDayClick() {
      console.log("click");
    },

    onAuthenticated: function() {
      this.authenticated = true;
      this.emailKey++;

      window.resize();
    },

    getPageSettings: function() {
      axios
        .get("/api/v1/list_configuration/" + this.configuration.id)
        .then(response => {
          // Don't overwrite the logo link, it's been authenticated already
          let data = response.data.data;
          let id = this.configuration.id;
          data.s3_logo_link = this.configuration.s3_logo_link;
          this.configuration = data;
          this.configuration.id = id;
        })
        .catch(err => {
          Vue.swal({
            icon: "error",
            title: "Error",
            text: "Error communicating with server"
          });
          console.log(err);
        });
    }
  },

  created() {
    let uri = window.location.href.split("?");

    if (uri.length == 2) {
      let vars = uri[1].split("&");
      let getVars = {};
      let tmp = "";

      vars.forEach(function(v) {
        tmp = v.split("=");
        if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
      });

      if (getVars.dbg) {
        console.info("Debug panel enabled");
        this.debug = true;
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", e => {
      if (this.keyInputString.length == 6) {
        this.keyInputString = this.keyInputString.slice(1, 6);
      }
      this.keyInputString =
        this.keyInputString + String.fromCharCode(e.keyCode);
      this.keyInputString = this.keyInputString.slice(0, 6);
      if (this.keyInputString == "!debug") this.debug = !this.debug;
    });

    this.getPageSettings();

    this.loadEvents();
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease-in-out;
}
.list-enter {
  transform: translateY(-30px);
  opacity: 0;
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.vc-pane-container {
  padding: 0.4em;
}

/* Hide the date on the timepicker custom fields */
.timepicker-field .vc-date {
  display: none;
}

#map {
  width: 100%;
  height: 50vh;
}
</style>
