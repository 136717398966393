/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';
import { ValidationProvider, extend } from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import VCalendar from 'v-calendar';
import VueSimpleSpinner from 'vue-simple-spinner';
import VueDateFns from "vue-date-fns";
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(VCalendar);
Vue.use(VueDateFns);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VueSimpleSpinner', VueSimpleSpinner);

// Some validations
extend('required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    computesRequired: true
});
extend('testing', value => {
    return value == 'testing';
});

window.Vue = Vue;
window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.config.productionTip = false
// Vue.prototype.$jquery = $

import App from './App.vue';
import EventList from './EventListApp.vue';
Vue.component('registration-component', App);
Vue.component('event-list-component', EventList);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    created() {
    },
    methods: {
    }
})
