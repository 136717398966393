<template>
  <div class="pb-4">
    <slot></slot>

    Email:
    <input type="text" @keyup.enter="submit" v-model="inputValue" @input="$emit('input', inputValue)">

  </div>
</template>

<script>
export default {
  name: "EmailEntry",
  created() {
  },
  data() {
    return {

      inputValue: null

    }
  },
  props: ['prefix'],
  methods: {
    submit: function() {
      if (!this.inputValue) return;

      let params = { 'p':this.inputValue, 'prefix':this.prefix };
      let vm = this;


      axios.post(
          '/api/v1/authenticate_email',params
      ).then(function (response) {
        vm.$emit('authenticated');
      })
          .catch((err) => {
            if (err.response.status == 401) {
              Vue.swal({icon: 'error', 'title': 'Authentication error', 'text':err.response.data.message});
            } else {
              Vue.swal({icon: 'error', 'title': 'Ooops...', 'text':'Sorry, something went wrong.'});
            }
      });

    }
  }
}
</script>

<style scoped>

</style>