<template>
    <div>
        <div v-for="event in events" v-bind:key="event.id" class="grid grid-cols-6 border border-gray-100 mb-4">
            <template v-if="event.start_timestamp">
            <div class="col-span-1 bg-blue-100 text-center h-full pb-4 pt-4">
                <p v-if="display('start_date')" class="font-bold uppercase leading-tight">{{ new Date(event.start_timestamp.replace(' ', 'T')) | date('MMM dd') }}</p>
                <p class="uppercase text-xs leading-tight">
                    <span v-if="display('start_time')">{{ new Date(event.start_timestamp.replace(' ', 'T')) | date('h:mm a') }}</span>
                    <span v-if="display('end_time')">- {{ new Date(event.end_timestamp.replace(' ', 'T')) | date('h:mm a') }}</span>
                </p>
                <p v-if="display('event_id')" class="pt-4 text-sm"># {{event.event_id}}</p>
            </div>
            <div class="col-span-3 pl-4 text-gray-600 pt-4 pb-4">

                <p v-if="display('asset')" class="font-bold uppercase">{{event.asset}}</p>
                <p class="font-bold" v-if="display('event_name')">{{event.name}}</p>
                <p v-if="display('venue_name')">{{event.venue_name}}</p>
                <p>
                    <span v-if="display('street_address1')">{{event.street_address1}}</span>
                    <span v-if="display('street_address2')"><br>{{event.street_address2}}</span>
                </p>
                <p>
                    <span v-if="display('city')">{{event.city}}, </span>
                    <span v-if="display('state')">{{event.state}}</span>
                    <span v-if="display('zip')">{{event.zip}}</span>
                </p>
            </div>
            <div class="col-span-2 text-gray-600 pt-4 pb-4 text-right pr-4">
                <p v-if="display('asset')">
                    <span>
                        {{event.asset}} 
                        <div class="w-5 h-5 rounded-full inline-flex align-middle" :style="{'background-color':((configuration.layout_settings.calendar_color_by||null) == 'asset' ? event.asset_color: 'transparent')}"></div> 
                    </span>
                </p>
                <p v-if="display('event_status')">
                    <span>
                        {{event.event_status}} 
                        <div class="w-5 h-5 rounded-full inline-flex align-middle" :style="{'background-color':((configuration.layout_settings.calendar_color_by||null) == 'event_status' ? event.event_status_color : 'transparent')}"></div> 
                    </span>
                </p>
            </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
  name: "EventListItem",
  methods: {
      display(key) {

          let el = this.fields.find((el)=>el.key == key);
          if (el) return el.enabled;

          return false;
      }
  },
  created() {
  },
  data() {
    return {
      }
  },
  props: ['events', 'fields', 'configuration']
}
</script>

<style scoped>

</style>