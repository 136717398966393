<template>
  <div>
    <slot></slot>

    <div class="sm:px-6 lg:px-8 flex flex-col justify-center py-12">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div class="text-center">
          <p class="inline-flex items-center mb-8">
            <img class="max-h-32" :src="configuration.s3_logo_link"  alt="Logo">
          </p>
        </div>
        <h2 class="text-skin-base mt-6 text-3xl font-extrabold text-center">
<!--          Sign in-->
        </h2>
      </div>

      <div class="sm:mx-auto sm:w-full sm:max-w-md mt-8">
        <div class="sm:rounded-lg sm:px-10 px-4 py-8 bg-white shadow-lg border-gray-100 border-2">
          <password-entry ref="password" v-if="configuration.password_authentication" v-bind:prefix="configuration.prefix"
                          @authenticated="onPasswordAuthenticated"></password-entry>
          <email-entry ref="email" v-model="email" v-if="configuration.email_authentication" v-bind:prefix="configuration.prefix"
                       @authenticated="onEmailAuthenticated"></email-entry>
          <div>
            <button @click="submit"
                    class="filter hover:brightness-110 hover:saturate-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-accent bg-skin-accent sm:w-auto flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm">
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import PasswordEntry from "./PasswordEntry.vue";
import EmailEntry from "./EmailEntry.vue";

export default {
  components: {PasswordEntry, EmailEntry},
  props: ['configuration'],
  name: "Gatekeeper",
  data() {
    return {
      isPasswordAuthenticated: false,
      isEmailAuthenticated: false,
      email: null
    }
  },
  computed: {
  },
  methods: {
    submit: function() {
      if (this.configuration.password_authentication) {
        this.$refs.password.submit();
      }

      if (this.configuration.email_authentication) {
        this.$refs.email.submit();
      }
    },

    onPasswordAuthenticated: function() {
      this.passwordAuthenticated = true;
      this.testAuthentication();
    },
    onEmailAuthenticated: function() {
      this.emailAuthenticated = true;
      this.testAuthentication();
    },
    testAuthentication: function() {
        let isPasswordAuthenticated = (this.configuration.password_authentication == false || (this.configuration.password_authentication && this.passwordAuthenticated));
        let isEmailAuthenticated = (this.configuration.email_authentication == false || (this.configuration.email_authentication && this.emailAuthenticated));

        if (isEmailAuthenticated) {
          if (this.configuration.requester_email) {
            this.$emit('input', this.email);
            this.$forceUpdate();
          }
        }

        if (isPasswordAuthenticated && isEmailAuthenticated) {
          this.$emit('authenticated', this.email);
        }

    }


  }
}
</script>

<style scoped>

</style>