<template>
  <div>

    <div v-if="options.length > 1 || !hide_auto">
      <label><slot></slot><span v-if="required" class="text-xs text-red-500"> (required)</span></label>
    </div>


    <div v-if="options.length == 1">
      <div v-for="(option, index) in options" :key="index">
        <input type="hidden" :name="name" value="option.id">
      </div>

      <div v-if="!hide_auto">
        <fieldset class="border-none" v-if="options.length < 6">
          <div class="mt-2 space-y-4">
            <div v-for="(option, index) in options" :key="index">
              <input type="radio" :checked="true" disabled>
              <label class="text-gray-500">
                {{ option.name }}
              </label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div v-if="options.length > 1 || prevent_auto_select">
      <help-text :value="help"></help-text>

      <div v-if="options.length >= 6">
        <select
            v-model="inputValue" @change="$emit('input', $event.target.value)" class="form-select h-input">
          <option v-for="(option,index) in options" :key="index" :value="option.id" >{{option.name}}</option>
        </select>
      </div>

      <fieldset class="border-none" v-if="options.length < 6">
        <div class="mt-2 space-y-4">
          <div v-if="first">
            <input class="" :id="name + '_none'" :name="name" type="radio"
                   :value="'-'" v-on:change="$emit('input', $event.target.value)"
                   :checked="inputValue == '-'">
            <label class="" :for="name + '_none'">
              {{ first }}
            </label>
          </div>
          <div v-for="(option, index) in options" :key="index">
            <input class="" :id="name + '_' + index" :name="name" type="radio"
                   :value="option.id" v-on:change="$emit('input', $event.target.value)"
                   :checked="inputValue == option.id">
            <label class="" :for="name + '_' + index">
              {{ option.name }}
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import HelpText from "./HelpText.vue";
export default {
  name: "SelectField",
  components: {HelpText},
  created() {
  },
  mounted() {

    if (this.options.length == 1 && !this.prevent_auto_select) {
      // only 1 option, auto select it
      // console.log(this.name, 'auto select', this.options[0].id);
      this.$emit('input', this.options[0].id);
    }

  },
  data() {
    return {
      inputValue: this.value
    }
  },
  props: ['value', 'options', 'name', 'first', 'prevent_auto_select', 'help', 'hide_auto', 'required']
}
</script>

<style scoped>

</style>