<template>
  <div>
    <label><slot></slot><span v-if="required" class="text-xs text-red-500"> (required)</span></label>
    <input type="number"
           v-model="inputValue" @input="$emit('input', inputValue)"
           :required="required" :autocomplete="autocomplete">
  </div>
</template>

<script>
export default {
  name: "NumberField",
  created() {
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  props: ['value', 'required', 'autocomplete']
}
</script>

<style scoped>

</style>