<template>
  <select v-model="inputValue" @change="$emit('input', inputValue)">
      <option></option>
      <option v-for="state in availableStates" :value="state.a">{{state.n}}</option>
      <option></option>
      <option v-for="province in availableProvinces" :value="province.a">{{province.n}}</option>
  </select>
</template>

<script>
export default {

  name: "StatesSelect",

  props: ["config"],

  computed: {
    availableStates: function() {

      if (!this.config || this.config.length == 0) return this.states;
      return this.states.filter((el) => this.config.find((state) => state == el.a));
    },
    availableProvinces: function() {

      if (!this.config || this.config.length == 0) return this.provinces;
      return this.provinces.filter((el) => this.config.find((state) => state == el.a));
    }
  },

  created() {

  },

  data() {
    return {
      inputValue: this.value,
      states: [{"n" : "Alabama", "a" : "AL"},{"n" : "Alaska", "a" : "AK"},{"n" : "Arizona", "a" : "AZ"},{"n" : "Arkansas", "a" : "AR"},{"n" : "California", "a" : "CA"},{"n" : "Colorado", "a" : "CO"},{"n" : "Connecticut", "a" : "CT"},{"n" : "Delaware", "a" : "DE"},{"n" : "District of Columbia", "a" : "DC"},{"n" : "Florida", "a" : "FL"},{"n" : "Georgia", "a" : "GA"},{"n" : "Hawaii", "a" : "HI"},{"n" : "Idaho", "a" : "ID"},{"n" : "Illinois", "a" : "IL"},{"n" : "Indiana", "a" : "IN"},{"n" : "Iowa", "a" : "IA"},{"n" : "Kansas", "a" : "KS"},{"n" : "Kentucky", "a" : "KY"},{"n" : "Louisiana", "a" : "LA"},{"n" : "Maine", "a" : "ME"},{"n" : "Maryland", "a" : "MD"},{"n" : "Massachusetts", "a" : "MA"},{"n" : "Michigan", "a" : "MI"},{"n" : "Minnesota", "a" : "MN"},{"n" : "Mississippi", "a" : "MS"},{"n" : "Missouri", "a" : "MO"},{"n" : "Montana", "a" : "MT"},{"n" : "Nebraska", "a" : "NE"},{"n" : "Nevada", "a" : "NV"},{"n" : "New Hampshire", "a" : "NH"},{"n" : "New Jersey", "a" : "NJ"},{"n" : "New Mexico", "a" : "NM"},{"n" : "New York", "a" : "NY"},{"n" : "North Carolina", "a" : "NC"},{"n" : "North Dakota", "a" : "ND"},{"n" : "Ohio", "a" : "OH"},{"n" : "Oklahoma", "a" : "OK"},{"n" : "Oregon", "a" : "OR"},{"n" : "Pennsylvania", "a" : "PA"},{"n" : "Puerto Rico", "a" : "PR"},{"n" : "Rhode Island", "a" : "RI"},{"n" : "South Carolina", "a" : "SC"},{"n" : "South Dakota", "a" : "SD"},{"n" : "Tennessee", "a" : "TN"},{"n" : "Texas", "a" : "TX"},{"n" : "Utah", "a" : "UT"},{"n" : "Vermont", "a" : "VT"},{"n" : "Virginia", "a" : "VA"},{"n" : "Washington", "a" : "WA"},{"n" : "West Virginia", "a" : "WV"},{"n" : "Wisconsin", "a" : "WI"},{"n" : "Wyoming", "a" : "WY"}],
      provinces: [{"n" : "Alberta", "a" : "AB"},{"n" : "British Columbia", "a" : "BC"},{"n" : "Manitoba", "a" : "MB"},{"n" : "New Brunswick", "a" : "NB"},{"n" : "Newfoundland And Labrador", "a" : "NL"},{"n" : "Northwest Territories", "a" : "NT"},{"n" : "Nova Scotia", "a" : "NS"},{"n" : "Nunavut", "a" : "NU"},{"n" : "Ontario", "a" : "ON"},{"n" : "Quebec", "a" : "QC"},{"n" : "Saskatchewan", "a" : "SK"},{"n" : "Yukon", "a" : "YT"}],
      available: this.config
      }
  },

}
</script>

<style scoped>

</style>