<template>
  <div class="timepicker-field">
    <label><slot></slot><span v-if="required" class="text-xs text-red-500"> (required)</span></label>
    <help-text :value="help"></help-text>
    <v-date-picker mode="time" v-model="inputValue" :minute-increment="15"
                   :timezone="timezone"
                   @input="emit" :required="required" >
      <template v-slot="{ inputValue, inputEvents }">
        <input
            class="bg-white border border-gray-300 px-2 py-1 rounded shadow-sm"
            :value="inputValue"
            v-on="inputEvents"
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
Date.prototype.getFormattedTime = function () {
  var hours = this.getHours() == 0 ? "12" : this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
  var minutes = (this.getMinutes() < 10 ? "0" : "") + this.getMinutes();
  var ampm = this.getHours() < 12 ? "AM" : "PM";
  var formattedTime = hours + ":" + minutes + " " + ampm;
  return formattedTime;
}

import HelpText from "./HelpText.vue";
export default {
  name: "TimeField",
  components: {HelpText},
  created() {
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  props: ['value', 'required', 'autocomplete', 'timezone', 'help'],
  methods: {
    emit: function() {

      // We don't store timezones in our timestamps so this just gives us the time part as it was entered.

      let date = new Date(this.inputValue);
      let hours = date.getUTCHours();
      let mins = date.getUTCMinutes();
      mins = mins < 10 ? '0'+mins : mins;
      let ampm = 'AM';
      if (hours > 12) {
        hours -= 12;
        ampm = 'PM';
      }

      this.$emit('input', hours + ':' + mins + ' ' + ampm);
    }
  }

}
</script>

<style scoped>

</style>