<template>
  <div>
    <label>
      <slot></slot>
      <span v-if="required" class="text-xs text-red-500">(required)</span>
    </label>
    <help-text :value="help"></help-text>

    <input
      :type="fieldType"
      v-model="inputValue"
      @input="$emit('input', inputValue)"
      :required="required"
      :autocomplete="autocomplete"
    />
  </div>
</template>

<script>
import HelpText from "./HelpText.vue";

export default {
  name: "TextField",
  components: { HelpText },
  created() {},
  data() {
    return {
      inputValue: this.value,
      fieldType: this.type ? this.type : "text"
    };
  },
  props: ["value", "required", "autocomplete", "type", "help"]
};
</script>

<style scoped>
</style>