<template>
<div class="text-lg font-bold"><slot></slot></div>
</template>

<script>
export default {
  name: "PageTitle"
}
</script>

<style scoped>

</style>