<template>
  <div class="text-sm text-gray-500 italic">{{value}}</div>
</template>

<script>
export default {
  name: "HelpText",
  props: ['value']
}
</script>

<style scoped>

</style>