<template>
  <div>
    <h3 class="text-lg text-skin-accent"><slot></slot></h3>
  </div>
</template>

<script>
export default {
  name: "SubsectionField"
}
</script>

<style scoped>

</style>