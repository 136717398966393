<template>
  <div>
    <gatekeeper
      v-if="!authenticated && requiresAuthentication"
      :configuration="configuration"
      v-model="response.email"
      @authenticated="onAuthenticated"
    ></gatekeeper>

    <div v-show="!requestSent && (authenticated || !requiresAuthentication)">
      <div class="max-w-7xl sm:px-6 px-4 py-12 mx-auto">
        <div class="text-center">
          <p class="inline-flex items-center mb-8">
            <img class="max-h-32" :src="configuration.s3_logo_link" alt="Logo" />
          </p>
          <p
            class="sm:text-5xl sm:tracking-tight lg:text-6xl mt-1 text-4xl font-extrabold"
          >{{ configuration.page_title }}</p>
          <div v-if="configuration.instructions" class="pt-8">
            <span v-html="configuration.instructions"></span>
          </div>
        </div>

        <div
          v-for="link in configuration.links"
          v-bind:key="link.id"
          class="text-center text-blue-500 pt-8"
        >
          <a
            v-if="!link.custom_domain"
            :href="'https://'+link.prefix+'.'+hostname"
          >Go To {{link.page_title}}</a>
          <a
            v-if="link.custom_domain"
            :href="'https://'+link.custom_domain+'/'+link.custom_domain_path"
          >Go To {{link.page_title}}</a>
        </div>
      </div>

      <div class="max-w-7xl sm:px-6 sm:py-12 pb-12 mx-auto space-y-8 divide-y divide-gray-200">
        <div class="sm:p-6 px-4 py-5 divide-y divide-gray-200 space-y-8">
          <div class="md:grid md:grid-cols-3">
            <div class="md:col-span-1">
              <h3 class="text-xl font-medium leading-6">Requester Information</h3>
              <p class="opacity-70 mt-1 text-sm">Your personal information so we can contact you.</p>
            </div>

            <div class="md:mt-0 md:col-span-2 mt-5">
              <div class="grid grid-cols-6 gap-6">
                <div
                  v-if="configuration.requester_first_name"
                  class="sm:col-span-3 col-span-6 pb-4"
                >
                  <text-field
                    v-model="response.first_name"
                    :autocomplete="'given-name'"
                    :required="configuration.first_name_required"
                    :value="response.first_name"
                    :help="configuration.layout_settings.first_name_help"
                  >First Name</text-field>
                  <span
                    v-if="getValidationError('first_name')"
                    class="text-red-500"
                  >{{ getValidationError('first_name').message }}</span>
                </div>

                <div v-if="configuration.requester_last_name" class="sm:col-span-3 col-span-6 pb-4">
                  <text-field
                    v-model="response.last_name"
                    :autocomplete="'family-name'"
                    :required="configuration.last_name_required"
                    :value="response.last_name"
                    :help="configuration.layout_settings.last_name_help"
                  >Last Name</text-field>
                  <span
                    v-if="getValidationError('last_name')"
                    class="text-red-500"
                  >{{ getValidationError('last_name').message }}</span>
                </div>

                <div v-if="configuration.requester_email" class="sm:col-span-3 col-span-6 pb-4">
                  <text-field
                    v-model="response.email"
                    :autocomplete="'email'"
                    :type="'email'"
                    :required="configuration.email_required"
                    :value.sync="response.email"
                    :key="emailKey"
                    :help="configuration.layout_settings.email_help"
                  >Email Address</text-field>
                  <span
                    v-if="getValidationError('email')"
                    class="text-red-500"
                  >{{ getValidationError('email').message }}</span>
                </div>

                <div v-if="configuration.requester_phone" class="sm:col-span-3 col-span-6 pb-4">
                  <text-field
                    v-model="response.phone"
                    :autocomplete="'tel'"
                    :required="configuration.phone_required"
                    :value="response.phone"
                    :help="configuration.layout_settings.phone_help"
                  >Phone Number</text-field>
                  <span
                    v-if="getValidationError('phone')"
                    class="text-red-500"
                  >{{ getValidationError('phone').message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sm:p-6 px-4 py-5">
          <div class="md:grid md:grid-cols-3">
            <div class="md:col-span-1">
              <h3 class="text-xl font-medium leading-6">Event Information</h3>
            </div>
            <div class="md:mt-0 md:col-span-2 mt-5">
              <div class="grid grid-cols-6">
                <div class="col-span-6">
                  <div v-if="configuration.requester_pick_event_name" class="col-span-6 pb-4">
                    <text-field
                      v-model="response.event_name"
                      :value="response.event_name"
                      :help="configuration.layout_settings.event_name_help"
                      :required="configuration.requester_pick_event_name"
                    >Event Name</text-field>
                    <span
                      v-if="getValidationError('event_name')"
                      class="text-red-500"
                    >{{ getValidationError('event_name').message }}</span>
                  </div>
                </div>

                <div v-if="configuration.requester_enter_description" class="col-span-6 pb-4">
                  <div class="col-span-6">
                    <text-field
                      v-model="response.description"
                      :value="response.description"
                      :help="configuration.layout_settings.event_description_help"
                      :required="configuration.requester_enter_description"
                    >Event Description</text-field>
                    <span
                      v-if="getValidationError('event_description')"
                      class="text-red-500"
                    >{{ getValidationError('event_description').message }}</span>
                  </div>
                </div>

                <div class="col-span-6 pb-4">
                  <div class="col-span-6">
                    <label for="some_other_field_to_prevent_autocomplete">
                      Venue Address
                      <span class="text-xs text-red-500">(required)</span>
                    </label>
                    <help-text :value="configuration.help_venue"></help-text>
                    <input
                      ref="autocomplete"
                      placeholder
                      type="text"
                      id="location"
                      autocomplete="off"
                    />
                    <span
                      v-if="getValidationError('venue')"
                      class="text-red-500"
                    >{{ getValidationError('venue').message }}</span>
                  </div>
                </div>
                
                <div
                  v-if="configuration.requester_pick_venue_name"
                  class="col-span-6 pb-4"
                >
                  <text-field
                    v-model="response.venue_name"
                    :name="'venue_name'"
                    :value="response.venue_name"
                    :help="configuration.layout_settings.venue_name_help"
                    :required="configuration.requester_pick_venue_name"
                  >Venue Name</text-field>
                  <span
                    v-if="getValidationError('venue_name')"
                    class="text-red-500"
                  >{{ getValidationError('venue_name').message }}</span>
                </div>

                <div
                  v-if="configuration.requester_pick_status"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.event_statuses)}"
                >
                  <select-field
                    v-model="response.status"
                    :name="'status'"
                    :value="response.status"
                    :hide_auto="configuration.hide_auto_selections"
                    :required="configuration.requester_pick_status"
                    :options="configuration.options.event_statuses"
                    :help="configuration.layout_settings.status_help"
                  >Event Status</select-field>
                  <span
                    v-if="getValidationError('status')"
                    class="text-red-500"
                  >{{ getValidationError('status').message }}</span>
                </div>

                <div
                  v-if="configuration.requester_pick_category"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.event_categories)}"
                >
                  <select-field
                    v-model="response.category"
                    :name="'category'"
                    :value="response.category"
                    :required="configuration.requester_pick_category"
                    :hide_auto="configuration.hide_auto_selections"
                    :options="configuration.options.event_categories"
                    :help="configuration.layout_settings.category_help"
                  >Event Category</select-field>
                </div>

                <div
                  v-if="configuration.requester_pick_type"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(availableEventTypes)}"
                >
                  <select-field
                    v-model="response.event_type"
                    :name="'type'"
                    :value="response.event_type"
                    :hide_auto="configuration.hide_auto_selections"
                    :required="configuration.requester_pick_type"
                    :options="availableEventTypes"
                    @input="filterVisibleCustomFields"
                    :help="configuration.layout_settings.event_type_help"
                  >Type</select-field>
                  <span
                    v-if="getValidationError('event_type')"
                    class="text-red-500"
                  >{{ getValidationError('event_type').message }}</span>
                </div>

                <div
                  v-if="configuration.requester_pick_program"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.programs)}"
                >
                  <select-field
                    v-model="response.program"
                    :name="'program'"
                    :value="response.program"
                    :hide_auto="configuration.hide_auto_selections"
                    :required="configuration.requester_pick_program"
                    :options="configuration.options.programs"
                    @input="onProgramChange"
                    :help="configuration.layout_settings.program_help"
                  >Program</select-field>
                  <span
                    v-if="getValidationError('program')"
                    class="text-red-500"
                  >{{ getValidationError('program').message }}</span>
                </div>

                <div
                  v-if="configuration.requester_pick_recap_definition"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.recap_definition)}"
                >
                  <select-field
                    v-model="response.recap_definition"
                    :name="'recap_definition'"
                    :value="response.recap_definition"
                    :options="configuration.options.recap_definitions"
                    @input="onRecapChange"
                    :required="configuration.requester_pick_recap"
                    :help="configuration.layout_settings.recap_definition_help"
                  >Recap Definition</select-field>
                </div>

                <div
                  v-if="configuration.requester_pick_market"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.markets)}"
                >
                  <select-field
                    v-model="response.market"
                    :name="'market'"
                    :value="response.market"
                    :hide_auto="configuration.hide_auto_selections"
                    :required="configuration.requester_pick_market"
                    :options="configuration.options.markets"
                    @input="onMarketChange"
                    :help="configuration.layout_settings.market_help"
                  >Market</select-field>
                  <span
                    v-if="getValidationError('market')"
                    class="text-red-500"
                  >{{ getValidationError('market').message }}</span>
                </div>


                <div
                  v-if="configuration.requester_pick_venue_type"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.venue_types)}"
                >
                  <select-field
                    v-model="response.venue_type"
                    :name="'venue_type'"
                    :value="response.venue_type"
                    :hide_auto="configuration.hide_auto_selections"
                    :required="configuration.requester_pick_venue_type"
                    :options="configuration.options.venue_types"
                    :help="configuration.layout_settings.venue_type_help"
                  >Venue Type</select-field>
                  <span
                    v-if="getValidationError('venue_type')"
                    class="text-red-500"
                  >{{ getValidationError('venue_type').message }}</span>
                </div>

                <div
                  v-if=" availableAssets.length > 0"
                  class="col-span-6"
                  :class="{'pb-4':showBottomPadding(configuration.options.availableAssets)}"
                >
                  <select-field
                    v-model="response.asset"
                    :name="'asset'"
                    :value="response.asset"
                    :first="configuration.asset_required ? false : 'None'"
                    :prevent_auto_select="!configuration.asset_required"
                    :hide_auto="configuration.hide_auto_selections"
                    :required="true"
                    :options="availableAssets"
                    @input="onAssetChange"
                    :help="configuration.layout_settings.asset_help"
                  >Tour</select-field>
                  <span
                    v-if="getValidationError('asset')"
                    class="text-red-500"
                  >{{ getValidationError('asset').message }}</span>
                </div>
              </div>
            </div>
          </div>
          <vue-simple-spinner v-if="calendarLoading" size="medium" />
        </div>

        <transition name="fade">
          <div v-if="showCalendar" class="sm:p-6 px-4 py-5 divide-y divide-gray-200 space-y-8">
            <div class="grid sm:grid-cols-3 sm:gap-6">
              <div class="sm:grid-cols-2 sm:col-start-2 sm:col-end-3 pb-2">
                <v-calendar
                  :columns="2"
                  :attributes="calendarAttributes"
                  :disabled-dates="disabledCalendarDates"
                  :available-dates="availableCalendarDates"
                  :timezone="'UTC'"
                  @dayclick="onDayClick"
                >
                  <template #day-popover="{ day, dayTitle, attributes }">
                    <div>
                      <div class="text-xs text-gray-300 font-semibold text-center">{{ dayTitle }}</div>
                      <div v-for="attr in attributes" :key="attr.key" :attribute="attr">
                        <p class="text-center" v-html="attr.popover.label + attr.customData.detail"></p>
                      </div>
                    </div>
                  </template>
                </v-calendar>
                <ul class="inline-flex">
                  <li class="pr-4">
                    <div class="bg-blue-400 w-5 h-5 rounded-full inline-flex align-middle"></div>Selected
                  </li>
                  <li class="pr-4">
                    <div class="bg-gray-200 w-5 h-5 rounded-full inline-flex align-middle"></div>Unavailable
                  </li>
                  <li class="pr-4">
                    <div class="bg-yellow-200 w-5 h-5 rounded-full inline-flex align-middle"></div>Requested
                  </li>
                  <li>
                    <div class="bg-red-300 w-5 h-5 rounded-full inline-flex align-middle"></div>Booked
                  </li>
                </ul>
                <span
                  v-if="getValidationError('days')"
                  class="text-red-500"
                >{{ getValidationError('days').message }}</span>
              </div>
              <div class="sm:col-span-2 sm:col-start-2 text-gray-500">
                <p class="italic">Click a date to request it. Click again to remove the selection.</p>
                <p
                  class="italic"
                  v-if="!this.calendarAvailability.multiday"
                >You may select multiple dates.</p>
              </div>
              <div class="sm:col-span-2 sm:col-start-2">
                <transition-group name="list" tag="div">
                  <div v-for="(day,index) in response.days" :key="day.id">
                    <div class="flex pb-2">
                      <div class>
                        <v-date-picker
                          :timezone="'UTC'"
                          mode="time"
                          v-model="day.date"
                          @input="onDayChange(day.id)"
                        />
                      </div>
                      <div class="p-2">to</div>
                      <div class="align-text-bottom">
                        <v-date-picker
                          :timezone="'UTC'"
                          mode="time"
                          v-model="response.daysEndTimes[index].date"
                          @input="onDayEndChange(day.id)"
                        />
                      </div>
                    </div>
                    <span v-if="day.error" class="text-red-500">{{day.error}}</span>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
        </transition>

        <div class="sm:p-6 px-4 py-5">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <h3 class="text-xl font-medium leading-6">Event Details</h3>
            </div>
            <div class="md:mt-0 md:col-span-2 mt-5">
              <template v-if="configuration.options">
                <custom-field
                  v-for="(field, index) in configuration.options.custom_fields"
                  :key="index"
                  :field="field"
                  :id="field.events_custom_id"
                  :errors="getValidationError('cust_'+field.events_custom_id)"
                  :conditions="configuration.options.custom_field_conditions"
                  :responses="response"
                  :debug="debug"
                  v-model="response['cust_'+field.events_custom_id]"
                ></custom-field>
              </template>
            </div>
          </div>
        </div>

        <div class="sm:p-6 px-4 py-5">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1"></div>
            <div class="md:mt-0 md:col-span-2 mt-5">
              <div v-if="submitting" class="flex">
                <vue-simple-spinner size="medium" />
              </div>
              <button
                v-if="!submitting"
                @click="submit()"
                class="filter hover:brightness-110 hover:saturate-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-accent bg-skin-accent sm:w-auto flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm"
              >Send request</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="requestSent">
      <div class="max-w-7xl sm:px-6 px-4 py-12 mx-auto">
        <div class="text-center">
          <p class="inline-flex items-center mb-8">
            <img class="max-h-32" :src="configuration.s3_logo_link" alt="Logo" />
          </p>
          <h2
            class="text-skin-accent text-base font-semibold tracking-wide uppercase"
          >Request Confirmation</h2>
          <p
            class="sm:text-5xl sm:tracking-tight lg:text-6xl mt-1 text-4xl font-extrabold"
          >Request Confirmed</p>
          <p
            class="opacity-70 max-w-xl mx-auto mt-5 text-xl"
          >Thank you for your request, below is your confirmation code</p>
        </div>
      </div>

      <div class="flex items-center mx-auto">
        <p class="bg-gray-50 px-6 py-4 mx-auto font-mono text-2xl rounded-md">{{ createdEventId }}</p>
      </div>

      <div class="flex items-center mx-auto">
        <p class="opacity-70 max-w-xl mx-auto mt-5 text-xl">
          <button
            @click="reset()"
            class="filter hover:brightness-110 hover:saturate-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-accent bg-skin-accent sm:w-auto flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm"
          >Request another date</button>
        </p>
      </div>
    </div>

    <div v-if="debug" class="m-4 p-4 border-2 border-gray-100">{{ response }}</div>
    <div v-if="debug && availableAssets.length == 0" class="m-4 p-4 border-2 border-red-400">No available Assets (are the markets assigned correctly to the assets?) Without assets this page will NOT work.</div>
    <div v-if="debug" class="m-4 p-4 border-2 border-blue-100">Available Assets: <br>{{ availableAssets }}</div>

    <div v-if="debug" class="m-4 p-4 border-2 border-red-100">{{ validationErrors }}</div>

    <div v-if="debug" class="text-xs font-mono">{{ configuration }}</div>
  </div>
</template>

<script>
import PageTitle from "./components/PageTitle.vue";
import TextField from "./components/TextField.vue";
import HelpText from "./components/HelpText.vue";
import SelectField from "./components/SelectField.vue";
import PasswordEntry from "./components/PasswordEntry.vue";
import EmailEntry from "./components/EmailEntry.vue";
import Gatekeeper from "./components/Gatekeeper.vue";
import CustomField from "./components/CustomField.vue";

export default {
  components: {
    TextField,
    HelpText,
    PageTitle,
    SelectField,
    PasswordEntry,
    EmailEntry,
    Gatekeeper,
    CustomField
  },

  props: ["config"],

  computed: {
    requiresAuthentication: function() {
      return (
        this.configuration.password_authentication ||
        this.configuration.email_authentication
      );
    },

    dates() {
      return this.response.days.map(day => day.date);
    },

    computedEmail: function() {
      return this.response.email;
    },

    calendarAttributes() {
      let attributes = [];

      // Checks to see if a date is already on use in a calendar so we don't overlap items on a day
      let dateIsOnCalendar = function(el) {
        let testDate = new Date(el + "T12:00");
        for (let attr of attributes) {
          for (let i in attr.dates) {
            if (attr.dates[i].toString() == testDate.toString()) {
              return true;
            }
          }
        }
        return false;
      };

      if (this.calendarAvailability.blocked)
        for (const eventDate of this.calendarAvailability.blocked) {
          attributes.push({
            customData: { booked: true, detail: eventDate.name },
            highlight: { color: "gray", fillMode: "light" },
            dates: new Date(eventDate.date + "T12:00"),
            popover: {
              label: "Unavailable - ",
              visibility: "hover",
              hideIndicator: true
            }
          });
        }

      for (const eventDate of this.calendarAvailability.events) {
        // The try-catch is to handle the data format of the updated backend and the older version too
        // when this code is fresher than the back end api code
        let dates = null;
        try {
          dates = JSON.parse(eventDate.date).map(el => new Date(el + "T12:00"));
        } catch (e) {
          dates =
            dateIsOnCalendar(eventDate.date) == false
              ? new Date(eventDate.date + "T12:00")
              : null;
        }

        attributes.push({
          customData: {
            booked: true,
            detail: this.configuration.show_details_on_calendar
              ? "<br>" +
                eventDate.name +
                "<br>" +
                eventDate.venue_name +
                "<br>" +
                eventDate.city +
                ", " +
                eventDate.state
              : ""
          },
          highlight: { color: "red", fillMode: "light" },
          dates: dates,
          popover: {
            label: "Unavailable - Event already scheduled",
            visibility: "hover",
            hideIndicator: true
          }
        });
      }

      for (const eventDate of this.calendarAvailability.requested) {
        // The try-catch is to handle the data format of the updated backend and the older version too
        // when this code is fresher than the back end api code
        let dates = null;
        try {
          dates = JSON.parse(eventDate.date)
            .filter(el => dateIsOnCalendar(el) == false)
            .map(el => new Date(el + "T12:00"));
        } catch (e) {
          dates =
            dateIsOnCalendar(eventDate.date) == false
              ? new Date(eventDate.date + "T12:00")
              : null;
        }

        attributes.push({
          customData: {
            booked: false,
            detail: "<br>Requested but not yet approved"
          },
          highlight: { color: "yellow", fillMode: "light" },
          dates: dates,
          popover: {
            label: "Available",
            visibility: "hover",
            hideIndicator: true
          }
        });
      }

      attributes.push({
        highlight: "blue",
        dates: this.dates,
        customData: { booked: false }
      });

      return attributes;
    },

    disabledCalendarDates() {
      return [
        { end: this.calendarAvailability.start },
        { start: this.calendarAvailability.end }
      ];
    },

    availableCalendarDates() {
      return this.calendarAvailability.available.map(el => new Date(el.date));
    }
  },

  data() {
    return {
      configuration: this.config,
      authenticated: false,
      availableAssets: [],
      availableEventTypes: [],
      calendarAvailability: {},
      calendarLoading: false,
      createdEventId: false,
      debug: false,
      emailKey: 0,
      keyInputString: "",
      requestSent: false,
      response: {
        days: [],
        daysEndTimes: []
      },
      submitting: false,
      showCalendar: false,
      validationErrors: [],
      hostname: window.location.hostname
        .split(".")
        .slice(-2)
        .join(".")
    };
  },

  methods: {
    log(...msg) {
      if (this.debug) {
        console.log(msg);
      }
    },

    reset: function() {
      location.reload();
    },

    onAuthenticated: function() {
      this.authenticated = true;
      this.emailKey++;
    },

    onDayChange: function(id) {
      // if this is after the end of day, end of day needs to be updated
      const start = this.response.days.find(el => el.id == id);
      const end = this.response.daysEndTimes.find(el => el.id == id);
      start.error = null;

      if (start.date > end.date) {
        end.date = start.date;
        start.error =
          "Start time must come before end time. Times have been adjusted.";
        // Vue.swal({icon: 'warning', 'title': 'Notice', 'text': 'Start time must come before end time. Your selected times have been adjusted.'});
      }
      this.$forceUpdate();
    },

    onDayEndChange: function(id) {
      // if this is before the end of day, start of day needs to be updated
      const start = this.response.days.find(el => el.id == id);
      const end = this.response.daysEndTimes.find(el => el.id == id);

      if (end.date < start.date) {
        start.date = end.date;
        // Vue.swal({icon: 'warning', 'title': 'Notice', 'text': 'Start time must come before end time. Your selected times have been adjusted.'});
      }
      this.$forceUpdate();
    },

    setMarket: function(id) {
      this.response.market = id;
      this.onMarketChange();
      this.$forceUpdate();
    },

    assetSelectionIsVisible: function() {
      return (
        this.configuration.requester_pick_asset &&
        this.response.program &&
        this.response.market
      );
    },

    validateField: function(field, validationRule) {
      let validations = validationRule.split("|");

      let valid = true;
      for (let rule of validations) {
        if (rule == "required") {
          if (this.response[field] && Array.isArray(this.response[field])) {
            valid = valid && this.response[field].length > 0;
          } else {
            valid = valid && !!this.response[field];
          }
        }
        if (rule == "email") {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          valid = valid && re.test(String(this.response[field]).toLowerCase());
        }
      }
      return valid;
    },

    validate: function() {
      this.validationErrors = [];

      if (this.configuration.first_name_required) {
        if (!this.validateField("first_name", "required"))
          this.addValidationError("first_name", "First Name is required");
      }
      if (this.configuration.last_name_required) {
        if (!this.validateField("last_name", "required"))
          this.addValidationError("last_name", "Last Name is required");
      }
      if (this.configuration.phone_required) {
        if (!this.validateField("phone", "required"))
          this.addValidationError("phone", "Phone number is required");
      }
      if (this.configuration.email_required) {
        if (!this.validateField("email", "required|email"))
          this.addValidationError("email", "A valid email address is required");
      }
      if (this.configuration.requester_pick_event_name) {
        if (!this.validateField("event_name", "required"))
          this.addValidationError(
            "event_name",
            "This field may not be left blank"
          );
      }
      if (this.configuration.requester_enter_description) {
        if (!this.validateField("description", "required"))
          this.addValidationError(
            "event_description",
            "This field may not be left blank"
          );
      }
      if (this.configuration.requester_pick_status) {
        if (!this.validateField("status", "required"))
          this.addValidationError("status", "An event status is required");
      }
      if (this.configuration.requester_pick_type) {
        if (!this.validateField("event_type", "required"))
          this.addValidationError("event_type", "An event type is required");
      }
      if (this.configuration.requester_pick_venue_name) {
        if (!this.validateField("venue_name", "required"))
          this.addValidationError("venue_name", "A venue name is required");
      }
      if (this.configuration.requester_pick_market) {
        if (!this.validateField("market", "required"))
          this.addValidationError("market", "An event market is required");
      }
      if (this.configuration.requester_pick_program) {
        if (!this.validateField("program", "required"))
          this.addValidationError("program", "A program is required");
      }
      if (this.configuration.requester_pick_category) {
        if (!this.validateField("category", "required"))
          this.addValidationError("category", "A category is required");
      }
      if (this.configuration.requester_pick_venue_type) {
        if (!this.validateField("venue_type", "required"))
          this.addValidationError("venue_type", "A venue type is required");
      }
      if (!this.validateField("venue", "required"))
        this.addValidationError("venue", "An address is required");
      if (!this.validateField("days", "required"))
        this.addValidationError("days", "A date is required");

      if (this.assetSelectionIsVisible()) {
        if (!this.validateField("asset", "required"))
          this.addValidationError("asset", "A selection is required");
      }

      for (let field of this.configuration.options.custom_fields) {
        if (field.required) {
          if (!this.validateField("cust_" + field.events_custom_id, "required"))
            this.addValidationError(
              "cust_" + field.events_custom_id,
              "A selection is required"
            );
        }
      }

      return this.validationErrors.length == 0;
    },

    addValidationError: function(key, message) {
      this.validationErrors.push({ key: key, message: message });
    },

    getValidationError: function(key) {
      return this.validationErrors.find(el => el.key == key);
    },

    onDayClick: function(day) {
      const isDisabled = day.isDisabled !== false;
      const isBooked = day.attributes[0] && day.attributes[0].customData.booked;
      if (isDisabled || isBooked) {
        return;
      }

      const idx = this.response.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.response.days.splice(idx, 1);
        this.response.daysEndTimes.splice(idx, 1);
      } else {
        if (this.calendarAvailability.multiday == true) {
          this.response.days = [];
          this.response.daysEndTimes = [];
        }

        let start = new Date(day.date);
        start.setHours(start.getHours() + 9);

        this.response.days.push({
          id: day.id,
          date: start
        });

        let offsetDate = new Date(day.date);
        offsetDate.setHours(offsetDate.getHours() + 17);

        this.response.daysEndTimes.push({
          id: day.id,
          date: offsetDate
        });
      }
      this.response.days.sort((a, b) => a.date - b.date);
      this.response.daysEndTimes.sort((a, b) => a.date - b.date);
    },

    // Filter the available event types by selected program
    onProgramChange: function() {
      const program = this.configuration.options.programs.find(el => {
        return el.id == this.response.program;
      });

      // If the event type is pre-selected there won't be an event type array so all this can be skipped.
      if (this.configuration.options.event_types) {
        this.availableEventTypes = this.configuration.options.event_types.filter(
          el => {
            return program.event_type_ids.includes(parseInt(el.id));
          }
        );

        // If an event type is already chosen that isn't in the list of available types
        // the value should be cleared.

        let availableEventTypeIds = this.availableEventTypes.map(el => {
          return el.id;
        });

        if (
          this.response.event_type &&
          !availableEventTypeIds.contains(this.response.event_type)
        ) {
          this.response.event_type = null;
        }
      }

      // Update the list of visible custom fields
      this.filterVisibleCustomFields();

      this.filterAssetsByProgramAndMarket();

      this.loadAvailableDates();
    },

    // Filter the available assets when the market is set
    onMarketChange: function() {
      this.filterAssetsByProgramAndMarket();

      this.onAssetChange();
    },

    filterAssetsByProgramAndMarket: function() {
      const config = this.configuration;

      if (!this.response.market || !this.response.program) {
        if (config.requester_pick_asset) {
          this.availableAssets = [];
          this.response.asset = null;
          return;
        }
      }

      if (this.configuration.options.assets) {
        this.availableAssets = this.configuration.options.assets.filter(el => {
          return (
            el.market_ids.includes(parseInt(this.response.market)) &&
            el.program_ids.includes(parseInt(this.response.program))
          );
        });
      }

      // If no asset is required there should always be a 'none' option
      if (config.requester_pick_asset) {
        if (
          this.availableAssets.length == 1 &&
          this.configuration.asset_required
        ) {
          this.response.asset = this.availableAssets[0].id;
        } else {
          this.response.asset = false;
        }
      }
    },

    onRecapChange: function() {
      this.loadAvailableDates();
    },

    onAssetChange: function() {
      this.log("Available assets", this.availableAssets);

      this.loadAvailableDates();
    },

    // Load the requestable dates based on program, recap and possibly asset
    loadAvailableDates: function() {
      this.response.days = [];
      this.response.daysEndTimes = [];
      this.showCalendar = false;

      if (this.response.program && this.response.recap_definition) {
        if (this.response.asset) {
          this.calendarLoading = true;
          // Handle asset selection of 'none'
          let asset = this.response.asset == "-" ? null : this.response.asset;

          axios
            .get("/api/v1/available_dates", {
              params: {
                recap_definition: this.response.recap_definition,
                asset: asset,
                program: this.response.program,
                prefix: this.configuration.prefix
              }
            })
            .then(response => {
              this.showCalendar = true;
              this.calendarAvailability = response.data.data;
              let start = new Date(this.calendarAvailability.start);
              let end = new Date(this.calendarAvailability.end);
              if (start > end) {
                Vue.swal({
                  icon: "error",
                  title: "Error",
                  text: "No dates are available to be requested"
                });
              }
              this.clearDateSelections();
              this.calendarLoading = false;
            })
            .catch(err => {
              Vue.swal({
                icon: "error",
                title: "Error",
                text: "Error communicating with server"
              });
              console.log(err);
            });
        }
      }
    },

    setCalendarDisabledDates: function(dates) {
      // the start and end returned are of the _avaiable_ dates, so these _unavailable_ ones are the opposite
      this.disabledCalendarDates = [{ end: dates.start }, { start: dates.end }];
    },

    clearDateSelections: function() {
      this.response.days = [];
    },

    getCustomFields: function() {
      axios
        .get("/api/v1/custom_fields/" + this.configuration.id)
        .then(response => {
          this.$set(
            this.configuration.options,
            "unfiltered_custom_fields",
            response.data.data
          );
          this.log(
            "Custom Fields",
            this.configuration.options.unfiltered_custom_fields
          );
          this.filterVisibleCustomFields();
          this.$forceUpdate();
        })
        .catch(err => {
          Vue.swal({
            icon: "error",
            title: "Error",
            text: "Error communicating with server"
          });
        });
    },

    populatePreSelectedValues: function() {
      const config = this.configuration;

      if (config.requester_pick_program == false) {
        this.response.program = config.program;
        this.onProgramChange();
      }

      if (config.requester_pick_asset == false) {
        this.response.asset = config.asset;
      }

      if (config.requester_pick_category == false) {
        this.response.category = config.category;
      }

      if (config.requester_pick_recap_definition == false) {
        this.response.recap_definition = config.recap_definition;
      }

      if (config.requester_pick_status == false) {
        this.response.event_status = config.event_status;
      }

      if (config.requester_pick_type == false) {
        this.response.event_type = config.event_type;
      }

      if (config.requester_pick_venue_type == false) {
        this.response.venue_type = config.venue_type;
      }

      if (config.requester_pick_market == false && config.market) {
        this.setMarket(config.market);
      }
    },

    /*
     * Custom fields display if they are assigned to the current program OR current event type.
     * Those values can be automatically set (if requester_pick_type and requester_pick_program are false)
     * any combination of them can be set manually.  After initial assignment and after any change to those
     * fields the custom fields to display are filtered from the full list.
     */
    filterVisibleCustomFields: function() {
      if (this.response.program || this.response.event_type) {
        let fields = [];

        if (this.configuration.options.unfiltered_custom_fields) {
          fields = this.configuration.options.unfiltered_custom_fields.filter(
            el => {
              return (
                el.program_id.includes(parseInt(this.response.program)) ||
                el.event_type_id.includes(parseInt(this.response.event_type))
              );
            }
          );
        }

        this.log("Custom fields", fields);

        this.$set(this.configuration.options, "custom_fields", fields);

        this.$forceUpdate();
      }
    },

    submit: function() {
      if (this.validate()) {
        this.submitting = true;

        this.response.prefix = this.configuration.prefix;
        axios
          .post("/api/v1/submit", this.response)
          .then(response => {
            if (response.data.success) {
              this.requestSent = true;
              this.createdEventId = response.data.id;
              this.$forceUpdate();
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },

    // When a dropdown has a single option it's auto-selected.  There's a toggle that controls whether to show or hide the
    // selection (which can't be changed).  If it's hidden there should be no bottom padding.
    showBottomPadding(arr) {
      return (
        arr &&
        (arr.length > 1 ||
          (arr.length == 1 && !this.configuration.hide_auto_selections))
      );
    },

    getPageSettings: function() {
      axios
        .get("/api/v1/request_site_configuration/" + this.configuration.id)
        .then(response => {
          // Don't overwrite the logo link, it's been authenticated already
          let data = response.data.data;
          this.configuration.links = data.links;
          this.$forceUpdate();
        })
        .catch(err => {
          Vue.swal({
            icon: "error",
            title: "Error",
            text: "Error communicating with server"
          });
          console.log(err);
        });
    }
  },

  created() {
    let uri = window.location.href.split("?");

    if (uri.length == 2) {
      let vars = uri[1].split("&");
      let getVars = {};
      let tmp = "";

      vars.forEach(function(v) {
        tmp = v.split("=");
        if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
      });

      if (getVars.dbg) {
        console.info("Debug panel enabled");
        this.debug = true;
      }
    }

    this.populatePreSelectedValues();
  },

  mounted() {
    let vm = this;

    window.addEventListener("keypress", e => {
      if (this.keyInputString.length == 6) {
        this.keyInputString = this.keyInputString.slice(1, 6);
      }
      this.keyInputString =
        this.keyInputString + String.fromCharCode(e.keyCode);
      this.keyInputString = this.keyInputString.slice(0, 6);
      if (this.keyInputString == "!debug") this.debug = !this.debug;
    });

    this.getPageSettings();

    this.getCustomFields();

    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["address"] }
    );

    this.autocomplete.setComponentRestrictions({ country: ["us", "ca"] });

    var input = document.getElementById("location");

    var selectFirstOnEnter = function(input) {
      // store the original event binding function
      var _addEventListener = input.addEventListener
        ? input.addEventListener
        : input.attachEvent;

      function addEventListenerWrapper(type, listener) {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
        if (type == "keydown") {
          var orig_listener = listener;
          listener = function(event) {
            var suggestion_selected =
              document.getElementsByClassName("pac-item-selected").length > 0;

            console.log(suggestion_selected);
            // enter or tab
            if (
              (event.which == 13 || event.which == 9) &&
              !suggestion_selected
            ) {
              var simulated_downarrow = new KeyboardEvent("keydown", {
                keyCode: 40,
                which: 40
              });
              orig_listener.apply(input, [simulated_downarrow]);
            }
            orig_listener.apply(input, [event]);
          };
        }
        _addEventListener.apply(input, [type, listener]); // add the modified listener
      }

      if (input.addEventListener) {
        input.addEventListener = addEventListenerWrapper;
      } else if (input.attachEvent) {
        input.attachEvent = addEventListenerWrapper;
      }
    };

    selectFirstOnEnter(input);

    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      let ac = place.address_components;

      const city = ac.find(item => item.types.includes("locality"));
      const state = ac.find(item =>
        item.types.includes("administrative_area_level_1")
      );
      const country = ac.find(item => item.types.includes("country"));
      const zip = ac.find(item => item.types.includes("postal_code"));
      const number = ac.find(item => item.types.includes("street_number"));
      const route = ac.find(item => item.types.includes("route"));

      vm.response.venue = {};
      vm.response.venue.city = city ? city.short_name : null;
      vm.response.venue.state = state ? state.short_name : null;
      vm.response.venue.zip = zip ? zip.short_name : null;
      vm.response.venue.street_address = (
        (number ? number.short_name : null) +
        " " +
        (route ? route.short_name : null)
      ).trim();
      vm.response.place_id = place.place_id;

      vm.response.lat = place.geometry.location.lat();
      vm.response.lng = place.geometry.location.lng();

      vm.$forceUpdate();

      if (vm.configuration.requester_pick_market == false) {
        let market = false;
        if (vm.configuration.market) {
          market = vm.configuration.market;
        } else {
          market =
            vm.configuration.market_assignment_by_state[state.short_name];
        }
        vm.setMarket(market);
      }

    });
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease-in-out;
}
.list-enter {
  transform: translateY(-30px);
  opacity: 0;
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.vc-pane-container {
  padding: 0.4em;
}

/* Hide the date on the timepicker custom fields */
.timepicker-field .vc-date {
  display: none;
}
</style>
